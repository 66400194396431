<template>
  <div id="app">
    <!-- 3D Background -->
    <Background3D />

    <!-- Content -->
    <div class="content">
      <header class="hero">
        <div class="hero-content">
          <h1 class="title">
            Hi, I'm <span class="highlight">Majd</span>
          </h1>
          <p class="subtitle">Developer | Innovator | Technologist</p>
         <p class="subtitle">I am Coming ...</p>
        </div>
      </header>

      <!-- About Section -->
      <section id="about" class="section about">
        <h2 class="section-title">About Me</h2>
        <p>
          I'm a developer specializing in cutting-edge web solutions, passionate about creating futuristic applications.
        </p>
      </section>

      <!-- Skills Section -->
      <section id="skills" class="section skills">
        <h2 class="section-title">Skills</h2>
        <div class="skills-container">
          <div v-for="skill in skills" :key="skill.name" class="skill">
            <h3>{{ skill.name }}</h3>
            <div class="skill-bar">
              <div class="skill-progress" :style="{ width: skill.level + '%' }"></div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import Background3D from "./Background3D.vue";

export default {
  components: { Background3D },
  data() {
    return {
      skills: [
        { name: "JavaScript", level: 90 },
        { name: "Vue.js", level: 85 },
        { name: "React.js", level: 80 },
        { name: "Spring Boot", level: 75 },
      ],
    };
  },
  methods: {
    scrollToSection(id) {
      document.getElementById(id).scrollIntoView({ behavior: "smooth" });
    },
  },
};
</script>

<style scoped>
/* Main Styles */

body {
  margin: 0;
  padding: 0;
  background: #0a0f0d; /* Dark black-green */
  font-family: "Orbitron", sans-serif;
  color: #c3ffc5;
}

#app {
  position: relative;
  overflow: hidden;
}

/* Hero Section */
.hero {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;
  z-index: 2; /* Make sure content is above the 3D background */
  position: relative;
  color: #c3ffc5;
}

.hero-content .title {
  font-size: 3rem;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
}

.hero-content .highlight {
  color: #2ef3b5;
  text-shadow: 0 0 8px #2ef3b5;
}

.hero-content .cta {
  margin-top: 20px;
  padding: 12px 30px;
  background: #2ef3b5;
  color: #0a0f0d;
  font-size: 1rem;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  transition: transform 0.3s ease-in-out, background-color 0.3s ease-in-out;
}

.hero-content .cta:hover {
  transform: scale(1.1);
  background-color: #1ecb91;
}
</style>
