<template>
  <div ref="canvasContainer" class="background-3d"></div>
</template>

<script>
import * as THREE from "three";

export default {
  mounted() {
    this.initThree();
  },
  methods: {
    initThree() {
      // Scene, Camera, Renderer
      const scene = new THREE.Scene();
      const camera = new THREE.PerspectiveCamera(
        75,
        window.innerWidth / window.innerHeight,
        0.1,
        1000
      );
      const renderer = new THREE.WebGLRenderer({ alpha: true });
      renderer.setSize(window.innerWidth, window.innerHeight);
      this.$refs.canvasContainer.appendChild(renderer.domElement);

      // Particle Geometry
      const particlesGeometry = new THREE.BufferGeometry();
      const particlesCount = 700; // Increased for denser particles
      const positions = new Float32Array(particlesCount * 3);

      for (let i = 0; i < particlesCount * 3; i++) {
        positions[i] = (Math.random() - 0.5) * 15; // Spread particles in space
      }

      particlesGeometry.setAttribute(
        "position",
        new THREE.BufferAttribute(positions, 3)
      );

      // Particle Material (small glowing squares)
      const particlesMaterial = new THREE.PointsMaterial({
        size: 0.05, // Smaller size for subtlety
        map: this.createSquareTexture(),
        transparent: true,
        opacity: 0.6,
        color: 0x2ef3b5, // Glowing neon green
      });

      const particles = new THREE.Points(particlesGeometry, particlesMaterial);
      scene.add(particles);

      // Ambient Light
      const ambientLight = new THREE.AmbientLight(0x404040, 1.5); // Subtle ambient light
      scene.add(ambientLight);

      // Camera Position
      camera.position.z = 6;

      // Animation Loop
      function animate() {
        requestAnimationFrame(animate);

        // Rotate Particles
        particles.rotation.y += 0.002; // Horizontal rotation
        particles.rotation.x += 0.001; // Slight vertical rotation

        renderer.render(scene, camera);
      }

      animate();

      // Handle Resize
      window.addEventListener("resize", () => {
        camera.aspect = window.innerWidth / window.innerHeight;
        camera.updateProjectionMatrix();
        renderer.setSize(window.innerWidth, window.innerHeight);
      });
    },

    // Helper: Create Square Texture
    createSquareTexture() {
      const size = 64; // Texture size
      const canvas = document.createElement("canvas");
      canvas.width = size;
      canvas.height = size;
      const ctx = canvas.getContext("2d");

      // Draw small glowing square
      ctx.fillStyle = "rgba(46, 243, 181, 1)"; // Neon green
      ctx.fillRect(0, 0, size, size);

      return new THREE.CanvasTexture(canvas);
    },
  },
};
</script>

<style scoped>
.background-3d {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #001f1a; /* Luxurious dark green background */
  z-index: 1; /* Place behind the content */
  overflow: hidden;
}
</style>
